<template>
  <v-sheet
      height="100vh"
      id="dashPage"
      ref="dashPage"
      style="box-shadow: -8px 0 20px rgba(86, 113, 134, 0.05); position: relative"
      :class="[
          { 'overflow-y-auto' : !SM },
          { 'wsRoundedLightLeft' : !noBorderRadius}
      ]"
      @scroll="setScrollPosition"
      :color="backgroundColor"
  >
    <v-row class="no-gutters flex-wrap flex-column fill-height">
      <!-- PreHeader -->
      <v-col cols="auto" class="shrink" >
        <v-sheet width="100%" dark :color="preheaderColor || wsDARKLIGHT">
          <div class="d-flex justify-center" style="width: 100%">
            <slot name="pre_header.wide"></slot>
            <slot name="pre_header"></slot>
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="auto" class="grow" style="height: 100px"  >
        <div class="fill-height"
        >
          <div class="d-flex fill-height">
            <div :style="`width: ${width}`" class="mx-auto fill-height">

              <v-row class="no-gutters flex-wrap flex-column fill-height">
                <!-- Header : Title and Subtitile -->
                <v-col v-if="!noHeader" cols="auto" class="shrink"
                       :class="[
                           { 'pt-3' : !noPadding},
                           { 'px-10' : !SM && !noPadding } ,
                           { 'px-4'  : SM && !noPadding } ,
                       ]"
                >

                  <!-- Header : Root and Subtitile -->
                  <h5  class="wsDARKLIGHT" :class="[{ 'mb-1' : !noPadding}]">
                    <ws-link
                        :to="rootLocation || null"
                        class="noUnderline hoverUnderline"
                       :color="rootLocation ? wsATTENTION : null"
                    >
                      <span :class="[{wsATTENTION : rootLocation}]">{{  root  }} </span>
                    </ws-link>
                    <span v-if="subtitle" >/</span>
                    <span v-if="subtitle">  {{ subtitle }}</span>
                  </h5>


                  <div class=" align-center justify-space-between"
                       :class="[{'d-flex' : !SM}]"
                  >
                    <!-- Header : Title and Subtitile -->
                    <div>
                      <div class="d-flex align-center "  >
                        <v-btn v-if="(icon || headerRoute) && !SM"
                               :to="headerRoute ? headerRoute : ''"
                               class="mr-2 ml-n2"
                               icon>
                          <v-icon    :color="wsACCENT">{{ icon ? icon : 'mdi-chevron-left' }}</v-icon>
                        </v-btn>
                        <h5 class="d-flex" v-if="title" style="font-size: 22px"> {{ title }}
                          <slot name="header_title_edit"></slot>
                        </h5>

                        <v-btn  v-if="$store.state.courses.nameEditAction" icon>
                          <v-icon

                              @click="editName = true"
                              :color="wsACCENT"
                              class="pointer">mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </div>


                    <!-- Header : Action -->

                    <div class="align-center" :class="[{'d-flex' : !SM}]">
                       <v-sheet v-if="filters.length > 0" width="100%">
                          <ws-search-filters
                              @input="$emit('filter',$event)" v-model="filtersData" :items="filters"
                              @search="$emit('run-server-search')"
                              :class="[{'mr-4' : !SM}]"
                              :server-search="filtersSearch || false"
                              :server-search-ajax="filtersSearchAjax || null"

                          />
                      </v-sheet>
                      <portal-target name="dash-page-header-action">
                        <slot  name="header_action"></slot>

                        <portal-target name="dash-page-header-action.desktop">
                          <slot v-if="!SM" name="header_action.desktop">
                          </slot>
                        </portal-target>

                      </portal-target>
                    </div>
                    <portal-target v-if="SM" name="dash-page-header-action.mobile">
                    <slot  name="header_action.mobile">

                    </slot>
                    </portal-target>


                  </div>

                </v-col>

                <v-col cols="auto" class="grow" style="height: 50px"
                       :class="[
                           { 'px-10' : !SM && !noPadding && !noBodyPadding } ,
                           { 'px-4'  : SM && !noPadding && !noBodyPadding}
                       ]"
                >

                  <div class="d-flex flex-wrap">
                    <v-chip v-if="clearFiltersCondition" class="pr-1 pl-5 mt-2 mr-2" :color="wsACCENT" dark >
                      <h5>{{  $t('ClearFilters')  }}</h5>
                      <v-btn @click="filtersData = {}; $emit('filter',{})" class="ml-2" icon small>
                        <v-icon >mdi-close-circle</v-icon>
                      </v-btn>

                    </v-chip>

                    <template   v-for="(data,key) in filtersData">
                      <v-chip
                          v-if="data !== null && data !== undefined && !Array.isArray(data) "
                          :key="key"
                          class="pr-1 pl-5 mt-2 mr-2"
                          outlined
                          :color="wsACCENT" dark >
                        <h5 v-if=" key !== 'search'">{{  getFilterText(key , data)  }}</h5>
                        <h5 v-else> {{$t('Search')}} : "{{ data }}"</h5>
                        <v-btn @click="eraseFilter(key)" :color="wsACCENT" class="ml-2" icon small>
                          <v-icon >mdi-close-circle</v-icon>
                        </v-btn>

                      </v-chip>
                      <template v-else-if="Array.isArray(data) " >
                        <v-chip v-for="(value, j ) in data"
                            :key="key + j"
                            class="pr-1 pl-5 mt-2 mr-2"
                            outlined
                            :color="wsACCENT" dark >
                          <h5>{{  getFilterText(key , value )  }}</h5>
                          <v-btn @click="eraseFilter(key , j)" :color="wsACCENT" class="ml-2" icon small>
                            <v-icon >mdi-close-circle</v-icon>
                          </v-btn>

                        </v-chip>
                      </template>

                    </template>

                  </div>

                  <slot v-if="loading" name="loading">
                    <v-sheet height="350" class="d-flex align-center justify-center">
                      <v-progress-circular size="82" :color="wsACCENT" indeterminate />
                    </v-sheet>
                  </slot>
                  <slot v-else-if="noContentCondition && !loading" name="no-content">
                    <wsBlankPage
                        :text="noContentText"
                        :icon="noContentIcon"
                        :button-text="noContentButtonText"
                        :button-action="noContentButtonAction"
                        :route="noContentButtonRoute"
                        :loading="noContentLoading"
                    />
                  </slot>
                  <slot v-if="noContentCondition && !SM && !loading" name="no-content.desktop">
                  </slot>
                  <slot v-if="noContentCondition && SM && !loading" name="no-content.mobile">
                    <wsBlankPage
                        :text="noContentText"
                        :icon="noContentIcon"
                        :button-text="noContentButtonText"
                        :button-action="noContentButtonAction"
                        :route="noContentButtonRoute"
                        :loading="noContentLoading"
                    />
                  </slot>


                  <slot v-if="!noContentCondition && !loading" name="default"></slot>
                  <slot v-if="!SM && !noContentCondition && !loading" name="defaultDesktop"></slot>
                  <slot v-if="SM && !noContentCondition && !loading" name="defaultMobile"></slot>
                  <slot v-if="!SM && !noContentCondition && !loading" name="default.desktop"></slot>
                  <slot v-if="SM && !noContentCondition && !loading" name="default.mobile"></slot>






                </v-col>
              </v-row>


            </div>
          </div>
        </div>
      </v-col>

    </v-row>

    <!-- Dialogs Slot -->
    <div>
      <slot name="dialog"></slot>
      <ws-dialog
          v-model="editName"
          :title="$t('EditName')"
          @save="saveTitleAction()"
      >
        <ws-text-field
            v-model="title"
            :placeholder="$t('EnterName')"
            :label="$t('Name')"
        />
      </ws-dialog>
    </div>

  </v-sheet>
</template>

<script>
import {mapState} from "vuex";
import wsSearchFilters from "@/components/pages/dashboard/UI/wsSearchFilters";
import wsBlankPage from "@/components/UI/wsBlankPage";
export default {
  name: "DashPageNew",
  components : {
    wsSearchFilters,
    wsBlankPage
  },
  props : {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    backgroundColor : {
      type: String,
      default : null,
    },
    filters : {
      type : Array,
      default() { return [] }
    },
    filtersInput : {
      type : Object,
      default: () => {}
    },
    root: {
      type: String,
    },
    rootLocation: {
      type: String
    },
    icon: {
      type: String
    },
    width : {
      type: String,
      default : '100%'
    },
    headerRoute : {
      type: String,
      default : ''
    },
    sideNavigation : {
      type : Array,
      default: () => []
    },
    sideNavigationNoLink : {
      type : Boolean,
      default : false
    },
    navigationRoute  : {
      type : String,
      default : ''
    },
    value : {
      type : String,
    },
    mobileTitle : {
      type : String,
      default : ''
    },
    preheaderColor : {
      type : String,
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    navigationReturnRoute : {
      type : String,
    },
    noContentCondition : {
      type : Boolean,
      default : false
    },
    noContentText : {
      type : String
    },
    noContentIcon : {
      type : String
    },
    noContentButtonText : {
      type : String
    },
    noContentButtonRoute : {
      type : String
    },
    noContentButtonAction : {
      type : Function
    },
    noContentLoading : {
      type : Boolean,
      default : false
    },
    loading : {
      type : Boolean,
      default : false
    },
    noHeader : {
      type : Boolean,
      default : false
    },
    noBorderRadius : {
      type : Boolean,
      default : false
    },
    filtersSearch : {
      type : Boolean,
      default : false
    },
    filtersSearchAjax : {
      type : Function,
    },
    noBodyPadding : {
      type : Boolean,
      default : false
    }

  },
  data() {
    return {
      editName : false,
      filtersData : {},
      scroll : 0,
    }
  },
  watch : {
    sideNavigation : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    pageNavigationItems : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    filters : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    filtersInput() {
      if ( this.filtersInput !== this.filtersData ) {
        this.filtersData = JSON.parse(JSON.stringify(this.filtersInput))
      }
    },
    scrollHeight(value) {
      this.$emit('scroll' , value)
    },
    $route (){
      if ( this.$refs.dashPage ) {
        let content = this.$refs.dashPage.$el
        if ( content ) {
          content.scrollTop = 0
          window.scrollTo(0, 0)
        }
      }
    },
    navigationReturnRoute() {
      this.$store.state.dashboard.pageNavigationReturnRoute = this.navigationReturnRoute
    }

  },
  computed : {
    ...mapState('dashboard',['pageNavigationItems']),
    scrollHeight() {
      if ( !this.$refs.dashPage ) {
        return 0
      }
      return this.$refs.dashPage.$el.scrollTop
    },
    element() {
      return this.$refs.dashPage
    },
    clearFiltersCondition() {
      let result = false

      Object.keys(this.filtersData).forEach(key=>{
        if ( this.filtersData[key] !== null && this.filtersData[key] !== undefined) {
          result = true
        }
      })

      return result
    }
  },
  methods : {
    handleRootClick() {
      setTimeout(() => {
        this.$emit('root')
      } , 10)
    },
    setScrollPosition() {
      let element = this.$refs.dashPage
      if ( !element ) {
        return
      }
      let scroll = element.$el.scrollTop
      this.$emit('scroll' , scroll)
    },
    eraseFilter(key , index ) {
      if ( !index ) {
        this.filtersData[key] = null
        this.filtersData = JSON.parse(JSON.stringify(this.filtersData))
      } else {
        this.filtersData[key].splice(index,1)
        this.filtersData[key] = JSON.parse(JSON.stringify(this.filtersData[key]))
        this.filtersData = JSON.parse(JSON.stringify(this.filtersData))
      }
      this.$emit('filter',this.filtersData)
      this.filtersData = JSON.parse(JSON.stringify(this.filtersData))



    },
    initPage() {
      if ( this.filtersInput ) {
        this.filtersData = JSON.parse(JSON.stringify(this.filtersInput))
      }
      if ( this.sideNavigation.length > 0 ) {
        this.$store.state.dashboard.displayPageNavigation = true
        this.$store.state.dashboard.pageNavigationRoot = this.navigationRoute
        this.$store.state.dashboard.pageNavigationItems = this.sideNavigation
        this.$store.state.dashboard.pageNavigationReturnRoute = this.navigationReturnRoute

        let currentRoute = this.sideNavigation.find( el => this.$route.path.includes(this.navigationRoute + '/' + el.value) )
        if ( currentRoute ) {
          this.$store.state.dashboard.pageNavigation = currentRoute.value
        }

      }
    },
    saveTitleAction() {
      this.$store.state.courses.nameEditAction(this.title);
      this.editName = false
    },
    getFilterText(key , value) {

      let filter = this.filters.find( el=>el.value === key )
      if ( !filter ) {
        return ''
      }
      if ( ['text' , 'text_number'].includes(filter.type) ) {
        return value
      }
      let item = filter.items.find( el=> el.value === value)

      if ( !item ) {
        return ''
      }
      return item.text

    },
  },
  beforeMount() {
    if ( !this.root ) {
      this.root = this.$t('Dashboard')
    }
  },
  mounted() {
    setTimeout(() => {
      let element = this.$refs.dashPage
      this.$emit('get-dom' , element )
    },300)

    this.initPage()

  },
  beforeDestroy() {
    this.$store.state.dashboard.pageNavigationReturnRoute = ''
    this.$store.state.dashboard.displayPageNavigation = false
    this.$store.state.dashboard.pageNavigationRoot = ''
    this.$store.state.dashboard.pageNavigationItems = []
  }
}
</script>

<style scoped>
</style>